.btn1 {
    width: 100%;
    height: 45px;
    border: 1px;
    text-align: left;
    // line-height: 45px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .btn1::after {
    width: 100%;
    height: 45px;
    border: 1px;
    text-align: left;
    // line-height: 45px;
    border: 1px #1465E9 solid;
    margin-right: 10px;
    margin-bottom: 10px;
  }

.select1{
    width: 100%;
    height: 30px;
}

.HongBtn-top {
  width: 150px;
  height: 52px;
  position: relative;
  margin: 3px;
  display: inline-block;
  .HongBtn-content {
    width: 100%;
    height: 100%;
    display: flex;
    .flexbox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .input-Btn {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 0px;
      }
      .HongBtn-top-tag {
        width: 43px;
        height: 5px;
        border-radius: 3px;
        background-color: #1465E9;
        position: absolute;
        top: 0px;
      }
    }
  }
}
.HongBtn-bottom {
  width: 150px;
  height: 52px;
  position: relative;
  margin: 3px;
  display: inline-block;
  .HongBtn-content {
    width: 100%;
    height: 100%;
    display: flex;
    .flexbox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .input-Btn {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 0;
      }
      .HongBtn-top-tag {
        width: 43px;
        height: 5px;
        border-radius: 3px;
        background-color: #1465E9;
        position: absolute;
        top: 46px;
      }
    }
  }
}
@primary-color: #1DA57A;