.linebox {
    color: black;
    font-weight: 500;
    padding-left: 45px;
    padding-right: 45px;
    .colordiv {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
        margin-top: 0;
        overflow: hidden;
    }

    .rgbbox {
        margin-top: 0;
        height: 36px;
        text-align: center;
        span {
            // line-height: 34px;
            // height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .value {
            width: 60px;
            text-align: center;
        }
    }

    .shadeSlider {
        // width: 100%;
        // // margin-top: 24px;
        // border-radius: 5px;
        height: 200px;
    }

    #wheelbox {
        text-align: center;
    }
    .cccc {
        width: 100%;
        height: 50px;
        div {
            float: left;
        }
        // .circle-picker {
        //     margin: 0 auto !important;
        //     padding: 0;
        //     span {
        //         padding: 7px;
        //         div {
        //             margin: 0 !important;
        //         }
        //     }
        // }
    }
}

@primary-color: #1DA57A;